<template>
  <div class="animated fadeIn">
  </div>
</template>

<script>
export default {
  name: 'admin-reports'
}
</script>
